import './styles/reset.css';
import './styles/App.css';

import Header from './components/header/Header';
import Main from './components/main/Main';
import Footer from './components/footer/Footer';

import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import appleTouchIcon from './assets/favicon/apple-touch-icon.png';
import favicon32 from './assets/favicon/favicon-32x32.png';
import favicon16 from './assets/favicon/favicon-16x16.png';

import GTMNoScript from './hooks/GTMNoScripts';
import LgpdModal from './hooks/LGPD/LGPD';

function App() {
  return (
    <Router>
      <div className="App">
      <GTMNoScript/>
        <Helmet>
          <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
          <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
          <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
          <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
          
          {/* Google Tag Manager */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-FZSSEZ344Y"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-FZSSEZ344Y');
            `}
          </script>
        </Helmet>

        <LgpdModal />

        <Header />
        <Main />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
