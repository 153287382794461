import "./Footer.css";
import footerinsta from "../../assets/img-footer/icon-insta.png";
import footerfb from "../../assets/img-footer/icon-fb.png";
import footerwpp from "../../assets/img-footer/icon-wpp.png";
import footerans from "../../assets/img-footer/cod-ans.webp";
import footerlogopc from "../../assets/img-footer/logo-footer.webp";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="redes__footer">

                <div className="ans__container">
                    <img
                        className="footer__logo__desktop"
                        src={footerlogopc}
                        alt="Logo Plano Santa Saúde"
                    />
                    <img
                        className="footer__ans"
                        src={footerans}
                        alt="Código ANS da Plano Santa Saúde"
                    />
                </div>

                <div className="redes__itens">
                    <a className="redes__item" href="https://www.facebook.com/profile.php?id=61561095125208" target="_blank" rel="noopener noreferrer">
                        <img src={footerfb} alt="ícone logo Facebook" />
                    </a>

                    <a className="redes__item" href="https://www.instagram.com/plano.santa.saude/" target="_blank" rel="noopener noreferrer">
                        <img src={footerinsta} alt="ícone logo Instagram" />
                    </a>

                    <a className="redes__item" href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20o%20Plano%20Santa%20Saúde." target="_blank" rel="noopener noreferrer">
                        <img src={footerwpp} alt="ícone logo WhatsApp" />
                    </a>
                </div>
            </div>


            <div className="copyright">
                <p>&copy; 2025 - <span>BL Saúde</span> - Corretora Autorizada - Todos os direitos reservados.</p>
                <p>Desenvolvido por: <span>Ferrarez Dev</span></p>
            </div>

        </footer>
    )
}

export default Footer;
