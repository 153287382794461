import "./Cobertura.css";
import hospitalicon from "../../../../assets/img-main/hospital.png";

const Cobertura = () => {
  return (
    <div className="cobertura__container">
      <div className="cobertura__titulo">
        <h2>HOSPITAIS CREDENCIADOS</h2>
      </div>

      <div className="rede__container">
        <div className="redes">
          <div className="redes__info">
            <img src={hospitalicon} alt="Ícone de um hospital" />
            <p>Cubatão</p>
          </div>

          <div className="redes__info-texto">
            <p>Hospital Ana Costa</p>
            <p>
              Av. 9 de Abril, 1601
              <br />
              Vila Nova
            </p>
          </div>
        </div>

        <div className="redes">
          <div className="redes__info">
            <img src={hospitalicon} alt="Ícone de um hospital" />
            <p>Santos</p>
          </div>

          <div className="redes__info-texto">
            <p>Hospital Infantil Gonzaga</p>
            <p>
              Av. Ana Costa, 411
              <br />
              Gonzaga
            </p>
          </div>
        </div>

        <div className="redes">
          <div className="redes__info">
            <img src={hospitalicon} alt="Ícone de um hospital" />
            <p>Santos</p>
          </div>

          <div className="redes__info-texto">
            <p>Santa Casa de Santos</p>
            <p>
              Av. Dr. Cláudio Luiz da Costa, 50
              <br />
              Jabaquara
            </p>
          </div>
        </div>

        <div className="redes">
          <div className="redes__info">
            <img src={hospitalicon} alt="Ícone de um hospital" />
            <p>Santos</p>
          </div>

          <div className="redes__info-texto">
            <p>Hospital SerPiero</p>
            <p>
              R. da Constituição, 584
              <br />
              Vila Matias
            </p>
          </div>
        </div>

        <div className="redes">
          <div className="redes__info">
            <img src={hospitalicon} alt="Ícone de um hospital" />
            <p>Santos</p>
          </div>

          <div className="redes__info-texto">
            <p>Hospital Beneficência Portuguesa de Santos</p>
            <p>
              Av. Bernardino de Campos, 47
              <br />
              Vila Belmiro
            </p>
          </div>
        </div>

        <div className="redes">
          <div className="redes__info">
            <img src={hospitalicon} alt="Ícone de um hospital" />
            <p>São Vicente</p>
          </div>

          <div className="redes__info-texto">
            <p>Hospital São José</p>
            <p>
              R. Frei Gaspar, 790
              <br />
              Centro
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cobertura;
