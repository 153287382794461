import "./CardPlanos.css";
import btnwpp from "../../../../assets/img-main/btn-plan-wpp.png";

const CardPlanos = () => {
  return (
    <section className="card-planos-section">
      <div className="card-planos-container">
        <div className="card-planos__adesao">
          <div className="card-planos__text">
            <h3>
              Plano de Saúde por{" "}
              <strong>
                Adesão:
                <br />
              </strong>
            </h3>
            <p>
              <strong>
                Destinado a profissionais de categorias específicas ou
                associados a entidades profissionais.
              </strong>{" "}
              Este tipo de plano oferece condições especiais de contratação
              devido à negociação coletiva, proporcionando acesso a uma ampla
              gama de serviços médicos e hospitalares com descontos
              significativos.
            </p>

            <div class="div-separate">
              <span class="style-div-separate"></span>
            </div>
          </div>

          <div className="card-planos__cards">
            <div className="card bg-plans adesao-card">
              <h2 className="plan-name">
                STANDARD POP
                <br />
                <span>SAÚDE</span>
              </h2>
              <p className="cod-ans">ANS 464.677/11-1</p>
              <p className="plan-starting-price">a partir de:</p>
              <h2 className="price">R$ 160,23</h2>
              <p className="age-group">
                <strong>De 00 a 18 anos</strong>
              </p>
              <p className="plan__room">
                <strong>Quarto Coletivo</strong>
                <br />
                (Em caso de Internação)
              </p>
              <div className="hospitals">
                <p>Hospital Infantil Gonzaga</p>
                <p>Hospital Beneficência Portuguesa</p>
                <p>Hospital São José de São Vicente</p>
                <p>Hospital Ana Costa de Cubatão</p>
                <p>Santa Casa de Santos</p>
                <p>E mais!</p>
              </div>

              <div className="quote-button-container">
                <a
                  className="quote-button"
                  href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20o%20Plano%20Santa%20Saúde."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="quote-button-text">Quero uma cotação </p>
                  <img src={btnwpp} alt="Ícone do WhatsApp"></img>
                </a>
              </div>
            </div>

            <div className="card bg-plans adesao-card">
              <h2 className="plan-name">
                STANDARD POP
                <br />
                <span>SAÚDE + ODONTO</span>
              </h2>
              <p className="cod-ans">ANS 464.677/11-1</p>
              <p className="plan-starting-price">a partir de:</p>
              <h2 className="price">R$ 173,32</h2>
              <p className="age-group">
                <strong>De 00 a 18 anos</strong>
              </p>
              <p className="plan__room">
                <strong>Quarto Coletivo</strong>
                <br />
                (Em caso de Internação)
              </p>
              <div className="hospitals">
                <p>Hospital Infantil Gonzaga</p>
                <p>Hospital Beneficência Portuguesa</p>
                <p>Hospital São José de São Vicente</p>
                <p>Hospital Ana Costa de Cubatão</p>
                <p>Santa Casa de Santos</p>
                <p>E mais!</p>
              </div>

              <div className="quote-button-container">
                <a
                  className="quote-button"
                  href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20o%20Plano%20Santa%20Saúde."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="quote-button-text">Quero uma cotação </p>
                  <img src={btnwpp} alt="Ícone do WhatsApp"></img>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="card-planos__empresa">
          <div className="card-planos__text card-planos__empresa__text">
            <h3>
              Plano de Saúde{" "}
              <strong>
                Empresarial:
                <br />
              </strong>
            </h3>
            Os planos empresariais são uma excelente solução para empresas que
            desejam oferecer benefícios de saúde aos seus colaboradores.
            <strong>
              Destinados a empresas com, no mínimo, 2 participantes
            </strong>
            , esses planos permitem condições vantajosas devido ao número de
            vidas incluídas, além de promoverem a saúde e o bem-estar dos
            funcionários.
            <div class="div-separate">
              <span class="style-div-separate"></span>
            </div>
          </div>

          <div className="card-planos__cards">
            <div className="card bg-plans empresarial-card">
              <h2 className="plan-name">
                STANDARD POP LITORAL
                <br />
                <span>SAÚDE + ODONTO</span>
              </h2>
              <p className="cod-ans">ANS 480.188/18-1</p>
              <p className="plan-starting-price">a partir de:</p>
              <h2 className="price">R$ 155,90</h2>
              <p className="age-group">
                <strong>De 00 a 18 anos</strong>
              </p>
              <p className="plan__room">
                <strong>Quarto Coletivo</strong>
                <br />
                (Em caso de Internação)
              </p>
              <div className="hospitals">
                <p>Hospital Infantil Gonzaga</p>
                <p>Hospital Beneficência Portuguesa</p>
                <p>Hospital São José de São Vicente</p>
                <p>Hospital Ana Costa de Cubatão</p>
                <p>Santa Casa de Santos</p>
                <p>E mais!</p>
              </div>

              <div className="quote-button-container">
                <a
                  className="quote-button"
                  href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20o%20Plano%20Santa%20Saúde."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="quote-button-text">Quero uma cotação </p>
                  <img src={btnwpp} alt="Ícone do WhatsApp"></img>
                </a>
              </div>
            </div>

            <div className="card bg-plans empresarial-card">
              <h2 className="plan-name">
                EXECUTIVO LITORAL
                <br />
                <span>SAÚDE + ODONTO</span>
              </h2>
              <p className="cod-ans">ANS 480.188/18-1</p>
              <p className="plan-starting-price">a partir de:</p>
              <h2 className="price">R$ 235,46</h2>
              <p className="age-group">
                <strong>De 00 a 18 anos</strong>
              </p>
              <p className="plan__room">
                <strong>Quarto Individual</strong>
                <br />
                (Em caso de Internação)
              </p>
              <div className="hospitals">
                <p>Hospital Infantil Gonzaga</p>
                <p>Hospital Beneficência Portuguesa</p>
                <p>Hospital São José de São Vicente</p>
                <p>Hospital Ana Costa de Cubatão</p>
                <p>Santa Casa de Santos</p>
                <p>E mais!</p>
              </div>

              <div className="quote-button-container">
                <a
                  className="quote-button"
                  href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20o%20Plano%20Santa%20Saúde."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="quote-button-text">Quero uma cotação </p>
                  <img src={btnwpp} alt="Ícone do WhatsApp"></img>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="card-planos__indfam">
          <div className="card-planos__text card-planos__indfam__text">
            <h3>
              Plano de Saúde{" "}
              <strong>
                Empresarial (MEI):
                <br />
              </strong>
            </h3>
            <p>
              <strong>Os planos de saúde para Microempreendedores Individuais (MEI) são
              ideais para o empreendedor que possui um CNPJ, mas não tem a
              necessidade de contratar funcionários.</strong> Exclusivamente para o
              próprio microempreendedor, esse plano oferece a possibilidade de
              incluir dependentes, proporcionando cuidados médicos com a mesma
              qualidade dos planos empresariais, mas com um custo mais
              acessível.
            </p>
            <div class="div-separate">
              <span class="style-div-separate"></span>
            </div>
          </div>

          <div className="card-planos__cards">
            <div className="card bg-plans empresarial-card">
              <h2 className="plan-name">
                STANDARD POP LITORAL
                <br />
                <span>SAÚDE + ODONTO</span>
              </h2>
              <p className="cod-ans">ANS 480.188/18-1</p>
              <p className="plan-starting-price">a partir de:</p>
              <h2 className="price">R$ 171,49</h2>
              <p className="age-group">
                <strong>De 00 a 18 anos</strong>
              </p>
              <p className="plan__room">
                <strong>Quarto Coletivo</strong>
                <br />
                (Em caso de Internação)
              </p>
              <div className="hospitals">
                <p>Hospital Infantil Gonzaga</p>
                <p>Hospital Beneficência Portuguesa</p>
                <p>Hospital São José de São Vicente</p>
                <p>Hospital Ana Costa de Cubatão</p>
                <p>Santa Casa de Santos</p>
                <p>E mais!</p>
              </div>

              <div className="quote-button-container">
                <a
                  className="quote-button"
                  href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20o%20Plano%20Santa%20Saúde."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="quote-button-text">Quero uma cotação </p>
                  <img src={btnwpp} alt="Ícone do WhatsApp"></img>
                </a>
              </div>
            </div>

            <div className="card bg-plans empresarial-card">
              <h2 className="plan-name">
                EXECUTIVO LITORAL
                <br />
                <span>SAÚDE + ODONTO</span>
              </h2>
              <p className="cod-ans">ANS 480.188/18-1</p>
              <p className="plan-starting-price">a partir de:</p>
              <h2 className="price">R$ 294,32</h2>
              <p className="age-group">
                <strong>De 00 a 18 anos</strong>
              </p>
              <p className="plan__room">
                <strong>Quarto Individual</strong>
                <br />
                (Em caso de Internação)
              </p>
              <div className="hospitals">
                <p>Hospital Infantil Gonzaga</p>
                <p>Hospital Beneficência Portuguesa</p>
                <p>Hospital São José de São Vicente</p>
                <p>Hospital Ana Costa de Cubatão</p>
                <p>Santa Casa de Santos</p>
                <p>E mais!</p>
              </div>

              <div className="quote-button-container">
                <a
                  className="quote-button"
                  href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20o%20Plano%20Santa%20Saúde."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="quote-button-text">Quero uma cotação </p>
                  <img src={btnwpp} alt="Ícone do WhatsApp"></img>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="card-planos__adesao">
          <div className="card-planos__text">
            <h3>
              Plano{" "}
              <strong>
                Santa Saúde Odonto:
                <br />
              </strong>
            </h3>
            <p>
              <strong>
                Ideal para quem busca cuidado odontológico de qualidade e um
                sorriso saudável.
              </strong>{" "}
              Este plano oferece uma ampla gama de serviços odontológicos,
              cobrindo desde urgências e emergências até tratamentos preventivos
              e especializados.
            </p>

            <div class="div-separate">
              <span class="style-div-separate"></span>
            </div>
          </div>

          <div className="card-planos__cards">
            <div className="card bg-plans adesao-card">
              <h2 className="plan-name">
                SANTA SAÚDE ODONTO
                <br />
                <span>PLANO PME</span>
              </h2>
              <p className="cod-ans">ANS 461.897/10-1</p>
              <p className="plan-starting-price">a partir de:</p>
              <h2 className="price">R$ 23,00</h2>
              <p className="age-group">
                <strong>De 02 a 29 vidas</strong>
              </p>
              <p className="plan__room">
                <strong> </strong>
                <br />
              </p>
              <div className="hospitals">
                <p>Urgência e Emergência</p>
                <p>Consultas</p>
                <p>Cirurgias e extrações</p>
                <p>Tratamento de canal</p>
                <p>Restaurações de resina</p>
                <p>E mais!</p>
              </div>

              <div className="quote-button-container">
                <a
                  className="quote-button"
                  href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20o%20Plano%20Santa%20Saúde."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="quote-button-text">Quero uma cotação </p>
                  <img src={btnwpp} alt="Ícone do WhatsApp"></img>
                </a>
              </div>
            </div>

            <div className="card bg-plans adesao-card">
              <h2 className="plan-name">
                SANTA SAÚDE ODONTO
                <br />
                <span>PESSOA FÍSICA </span>
              </h2>
              <p className="cod-ans">ANS 461.896/10-3</p>
              <p className="plan-starting-price">a partir de:</p>
              <h2 className="price">R$ 35,00</h2>
              <p className="age-group">
                <strong>INDIVIDUAL</strong>
              </p>
              <p className="plan__room">
                <strong> </strong>
                <br />
              </p>
              <div className="hospitals">
                <p>Urgência e Emergência</p>
                <p>Consultas</p>
                <p>Cirurgias e extrações</p>
                <p>Tratamento de canal</p>
                <p>Restaurações de resina</p>
                <p>E mais!</p>
              </div>

              <div className="quote-button-container">
                <a
                  className="quote-button"
                  href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20o%20Plano%20Santa%20Saúde."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="quote-button-text">Quero uma cotação </p>
                  <img src={btnwpp} alt="Ícone do WhatsApp"></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardPlanos;
