import "./Home.css";
import { Helmet } from "react-helmet";

import Carousel from "./carousel/Carousel.js";
import BenefitsCarousel from "./benefits/Benefits.js";
import usePageTracking from "../../../hooks/usePageTracking.js";
import useSchemaOrg from "../../../hooks/SchemaOrg.js";

const Home = () => {
 

  // Custom data for Schema.org
  const customData = {
    description:
      "Conheça o Plano Santa Saúde e tenha acesso a um atendimento médico de qualidade com cobertura completa. Sempre perto, cuidando de você.",
    url: "https://planosantasaude.com/",
  };

useSchemaOrg(customData);

  usePageTracking();

  return (
    <div className="home">
      <Helmet>
        <title>Plano Santa Saúde | Sempre perto, cuidando de você</title>
        <meta
          name="description"
          content="Conheça o Plano Santa Saúde e tenha acesso a um atendimento médico de qualidade com cobertura completa. Sempre perto, cuidando de você."
        />
      </Helmet>
      <Carousel />
      <BenefitsCarousel />
    </div>
  );
};

export default Home;
