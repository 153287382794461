import React from "react";
import { useNavigate } from "react-router-dom";
import "./Channel.css";

import wpplogo from "../../../../assets/img-main/wpplogo.png";
import handshake from "../../../../assets/img-main/handshake.png";

const Channel = () => {
  const navigate = useNavigate();

  return (
    <div className="canais">
      <div className="canais__links">
        <button
          className="canais__online canal__whatsapp"
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20o%20Plano%20Santa%20Saúde.",
              "_blank"
            )
          }
        >
          <img src={wpplogo} alt="Logo do whatsapp" />
          <p>Contrate Agora</p>
        </button>
      </div>

      <div className="canais__links">
        <button className="canais__online" onClick={() => navigate("/cliente")}>
          <img src={handshake} alt="Icone de vendas" />
          <p>Contrate Agora</p>
        </button>
      </div>
    </div>
  );
};

export default Channel;
