import "./About.css";

import { Helmet } from "react-helmet";

import AboutInfo from "./aboutinfo/AboutInfo.js";
import Abrangencia from "./abrangencia/Abrangencia.js";
import Mission from "./mission/Mission.js";
import Cobertura from "./cobertura/Cobertura.js";
import usePageTracking from "../../../hooks/usePageTracking.js";

import familyimg from "../../../assets/img-main/family.png";
import useSchemaOrg from "../../../hooks/SchemaOrg.js";

const About = () => {
  
  // Custom data for Schema.org
  const customData = {
    description:
      "Saiba mais sobre o Plano Santa Saúde, nossa história, missão, visão e valores. Comprometidos com a saúde e o bem-estar da sua família.",
    url: "https://planosantasaude.com/sobre",
  };

  useSchemaOrg(customData);

  usePageTracking();

  return (
    <div className="about__container">
      <Helmet>
        <title>Sobre Nós | Plano Santa Saúde</title>
        <meta
          name="description"
          content="Saiba mais sobre o Plano Santa Saúde, nossa história, missão, visão e valores. Comprometidos com a saúde e o bem-estar da sua família."
        />
      </Helmet>
      <section className="about__banner-container">
        <div className="about__banner">
          <img
            className="banner__img"
            src={familyimg}
            alt="Imagem de uma família"
          />
        </div>

        <AboutInfo />
      </section>

      <section className="additional__info">
        <Mission />
      </section>

      <section className="abrangencia">
        <Abrangencia />
      </section>

      <section className="cobertura">
        <Cobertura />
      </section>
    </div>
  );
};

export default About;
