import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import "./Client.css";
import logo2 from "../../../assets/logo2.png";
import Form from "./form/Form.js";
import usePageTracking from "../../../hooks/usePageTracking.js";
import useSchemaOrg from "../../../hooks/SchemaOrg.js";

function Client() {
  
  // Custom data for Schema.org
  const customData = {
    description:
      "Adquira o Plano Santa Saúde e tenha acesso a uma rede completa de atendimento médico. Planos acessíveis e cobertura abrangente para você e sua família.",
    url: "https://planosantasaude.com/cliente",
  };

  useSchemaOrg(customData);

  usePageTracking();

  const navigate = useNavigate();

  const handleFormSubmitted = () => {
    navigate("/confirmacao");
  };

  return (
    <div className="contact">
      <Helmet>
        <title>Seja Cliente | Plano Santa Saúde</title>
        <meta
          name="description"
          content="Adquira o Plano Santa Saúde e tenha acesso a uma rede completa de atendimento médico. Planos acessíveis e cobertura abrangente para você e sua família."
        />
      </Helmet>
      <div className="contact__info">
        <img className="contact__logo" src={logo2} alt="Logo da empresa" />

        <p className="contact__subtitle">
          SEMPRE PERTO,
          <br /> CUIDANDO DE VOCÊ!
        </p>
        <p className="contact__p">
          Economize até 40% utilizando <br />
          seu CNPJ a partir de 2 vidas!
        </p>
      </div>

      <Form onFormSubmitted={handleFormSubmitted} />
    </div>
  );
}

export default Client;
